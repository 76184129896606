<template>
  <div>
    <v-icon small class="delete-button" v-on:click="deleteFile">
      mdi-delete
    </v-icon>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    file: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  methods: {
    deleteFile() {
      util
        .http({
          url: this.$props.name + "/file-remove",
          method: "POST",
          data: this.$props.file.row,
        })
        .then((res) => {
          if (res.data.status == 1) {
            util.event.$emit("file-deleted", this.$props.file.row);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
<style scoped>
.delete-button {
  cursor: pointer;
}
</style>
