<template lang="">
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="#1d2b58" style="color: white"
        >Choose Template <v-icon>mdi-pencil</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Choose Template
      </v-card-title>

      <v-card-text>
        <v-item-group>
          <v-container>
            <v-row>
              <v-col v-for="n in templates" :key="n.id" cols="12" md="4">
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'primary' : ''"
                    class="d-flex align-center"
                    dark
                    height="200"
                    @click="clicked(toggle, n.id)"
                  >
                    <!-- <v-scroll-y-transition>
                            <div
                            v-if="active"
                            class="text-h4 flex-grow-1 text-center"
                            >
                            Active
                            </div>
                        </v-scroll-y-transition> -->
                    <img
                      :src="n.image"
                      width="100%"
                      height="100%"
                      :style="n.id == id ? 'border:3px solid #000' : ''"
                    />
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Rest from "@/components/shared/rest.js";
export default {
  props: {
    show_template: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Function,
    },
    template_id: {
      type: Number,
    },
  },
  mounted() {
    this.fetchTemplates();
  },
  data() {
    return {
      dialog: false,
      templates: [],
      id: null,
    };
  },
  methods: {
    clicked(fun, id) {
      fun();
      this.id = id;
      this.$emit("input", id);
    },
    fetchTemplates() {
      Rest.get.call(this, { resource: "template" });
    },
  },
  watch: {
    show_template: function (val) {
      this.dialog = val;
    },
    "$props.template_id": function (val) {
      this.id = val;
    },
  },
};
</script>
